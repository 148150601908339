;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"a6a5c7c4119b805bcec80b83956dbb587f2db013"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const IS_NOT_PRODUCTION = process.env.NODE_ENV !== "production"

Sentry.init({
  dsn: SENTRY_DSN || "https://ac562ae7aa044d40b1265c2a96c9c70a@o542074.ingest.sentry.io/5661209",
  tracesSampleRate: IS_NOT_PRODUCTION ? 1.0 : 0.01,
  replaysSessionSampleRate: IS_NOT_PRODUCTION ? 1.0 : 0.01,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    "Amplitude Logger [Error]: TypeError: Load failed",
    "Amplitude Logger [Error]: Load failed",
    "Amplitude Logger [Error]: TypeError: Failed to fetch",
    "Amplitude Logger [Error]: TypeError: cancelled",
    "TypeError: Load failed",
    "TypeError: null is not an object (evaluating 't.currentSource=e')",
    "TypeError: null is not an object (evaluating 'e.s_e_b_e')",
    "TypeError: Right side of assignment cannot be destructured",
    "Loading initial props cancelled",
    "Abort fetching component for route",
    "Failed to load static props",
  ],
  integrations: [
    Sentry.extraErrorDataIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
  ],
})
