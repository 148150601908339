//This file contains all the logic involved with retrieving and manipulating
//the questions of the web funnel (referred to as the survey)

import { createContext, Dispatch, useContext, useEffect, useReducer } from "react"
import * as Sentry from "@sentry/nextjs"
import { trackAmplitudeAndGTAGEvent } from "@services/client/events"
import { firestore } from "@services/client/firebaseClient"
import { doc, getDoc, setDoc } from "firebase/firestore"
import _ from "lodash"

import { setAmplitudeUserProperties, setAmplitudeUserProperty } from "../../services/client/amplitude"
import { Question } from "./question"

//The list of questions
export interface SurveyConfig {
  milestones: number[]
  questions: Question[]
}

//The state used for the progress bar above some questions
export interface StepperState {
  totalSteps: number
  step: number
  progress: number
}

export type Answer = string | string[] | number | boolean | null | undefined

export type Answers = {
  [name: string]: Answer
}

// Define available survey themes
export const surveyThemes = [
  "fasting",
  "keto",
  "pinterest",
  "tiktok",
  "foodscanner",
  "weight-loss",
  "women-fasting",
  "men-fasting",
  "beginners-keto",
  "women-keto",
  "men-keto",
  "us",
  "generic",
  "outbrain",
] as const

export type SurveyTheme = (typeof surveyThemes)[number]

// Defining available funnels
export const validFunnels = [
  "default",
  "no_loader",
  "no_loader_track",
  "insurance",
  "funnelfox",
  "whatsapp",
  "trial_upgrade",
  "undefined",
] as const

export type Funnel = (typeof validFunnels)[number]

//These skip the straight to the paywall, used for external funnels
export const externalFunnels = ["funnelfox", "whatsapp"]

// Will contain insurance steps
export const insuranceFunnels = ["no_loader", "no_loader_track", "insurance"] as const satisfies readonly Funnel[]

export type InsuranceFunnel = (typeof insuranceFunnels)[number]

export const isInsuranceFunnel = (funnel: Funnel): boolean => insuranceFunnels.includes(funnel as InsuranceFunnel)

export type SurveyState = SurveyStateLoading | SurveyStateInitialized

export interface SurveyStateLoading {
  initialized: false
}

export interface SurveyStateInitialized {
  initialized: true
  config: SurveyConfig
  uid: string
  theme: SurveyTheme
  funnel: Funnel | undefined
  stepper: StepperState
  milestoneStepper: StepperState
  currentQuestion?: Question
  prevQuestion?: Question
  nextQuestion?: Question
  canStepNext?: boolean
  answers: Answers
}

export interface SurveyExperiments {
  insuranceWithYourProgram: boolean
  insuranceFlowVariant: string
}

//Actions used to modify the survey
export type Action =
  | {
      type: "SURVEY_LOADED"
      uid: string
      theme: SurveyTheme
      funnel: Funnel | undefined
      config: SurveyConfig
      experiments: SurveyExperiments
      answers?: Answers
      initialPath?: string
    }
  | { type: "QUESTION_ANSWER_CHANGED"; name: string; value?: Answer }
  | { type: "QUESTION_COMPLETED" }
  | { type: "QUESTION_STEP_BACK" }
  | { type: "SET_QUESTION"; path: string }

//Gets the next or previous question
const getSiblingQuestion = (questions: Question[], source: string, prev?: boolean): Question | null => {
  const sourceIndex = questions.findIndex((q) => q.name === source)
  //Check if should skip next question based on next question name + amplitude variants
  if (prev) {
    return sourceIndex > 0 ? questions[sourceIndex - 1] : null
  }
  return sourceIndex < questions.length - 1 ? questions[sourceIndex + 1] : null
}

//Checks if the question has been answered correctly
const validateQuestion = (question: Question, answers: Answers): boolean => {
  if (!question.isRequired) return true
  if (question.validate) return question.validate(question, answers)

  if (question.name === "gender" || question.type === "select") {
    const answer = answers[question.name]
    return Array.isArray(answer) && answer.length > 0
  }
  return true
}

//Check how far we are through the progress bar stepper
const computeStepperProgress = (config: SurveyConfig, currentStep: number): StepperState => {
  const totalSteps = config.questions.findIndex((q) => q.name === "program")
  const step = currentStep
  const progress = (step * 100) / totalSteps

  return {
    totalSteps,
    step,
    progress,
  }
}

const computeMilestonStepperProgress = (config: SurveyConfig, currentStep: number): StepperState => {
  for (let i = config.milestones.length - 1; i >= 0; i--) {
    if (currentStep === config.milestones[i]) {
      return {
        totalSteps: config.milestones.length - 1,
        step: i,
        progress: 0.0,
      }
    }
    if (currentStep > config.milestones[i] || i === 0) {
      return {
        totalSteps: config.milestones.length - 1,
        step: i,
        progress:
          i == config.milestones.length - 1
            ? 100
            : (currentStep - config.milestones[i]) / (config.milestones[i + 1] - 1 - config.milestones[i]),
      }
    }
  }
  return { totalSteps: config.milestones.length - 1, step: 0, progress: 0 }
}

//Prepares the next question state
const createNextStateWithQuestion = (state: SurveyStateInitialized, question: Question): SurveyState => {
  const questionIndex = state.config.questions.findIndex((q) => q.name === question.name)
  return {
    ...state,
    stepper: computeStepperProgress(state.config, questionIndex),
    milestoneStepper: computeMilestonStepperProgress(state.config, questionIndex + 1),
    currentQuestion: question,
    prevQuestion: getSiblingQuestion(state.config.questions, question.name, true),
    nextQuestion: getSiblingQuestion(state.config.questions, question.name),
    canStepNext: validateQuestion(question, state.answers),
  }
}

//Some strange logic when working with choiceGroups on a question
const computeAnswer = (question: Question, current: Answer, next: Answer): Answer => {
  if (question.type === "select") {
    // Check choice group and clear previous selected choices if required
    if (question.choiceGroups && question.choiceGroups.length > 0 && current && (current as string[]).length > 0) {
      const change = ((next ?? []) as string[]).filter((x) => !(current as string[]).includes(x))
      if (change.length > 0) {
        const previousGroups = question.choiceGroups.filter((choices) =>
          (current as string[]).every((v) => choices.includes(v))
        )
        let nextGroup = previousGroups.find((choices) => change.every((v) => choices.includes(v)))
        if (!nextGroup) {
          // find first matching group as fallback
          nextGroup = question.choiceGroups.find((choices) => change.every((v) => choices.includes(v)))
        }
        if (!previousGroups.every((choices) => choices.every((c) => nextGroup.includes(c)))) {
          return ((next ?? []) as string[]).filter((v) => nextGroup.includes(v))
        }
      }
    }
  }
  return next
}

//Finds the first question to send the user to
const computeInitialQuestion = (config: SurveyConfig, answers: Answers, initialPath?: string): Question => {
  // if a specific path is requested, set this as initial question
  if (initialPath) {
    const q = config.questions.find((q) => q.path === initialPath)
    if (q && validateQuestion(q, answers) && (!q.shouldRender || q.shouldRender(answers))) {
      return q
    }
  }

  const firstInvalid = config.questions.findIndex(
    (q) => !validateQuestion(q, answers) && (!q.shouldRender || q.shouldRender(answers))
  )
  if (firstInvalid === -1) {
    return config.questions.find((q) => q.path === "your_program")
  }
  return config.questions[firstInvalid]
}

//
const reducer = (state: SurveyState, action: Action): SurveyState => {
  switch (action.type) {
    case "SURVEY_LOADED": {
      const answers = {
        ...(action.answers ?? {}),
      }

      // migrate age values which had choice style
      if (answers["age"] && Array.isArray(answers["age"]) && answers["age"].length > 0) {
        const decade = answers["age"][0].split("-")[0]
        answers["age"] = +decade
      }

      const initialQuestion = computeInitialQuestion(action.config, answers, action.initialPath)
      const questionIndex = action.config.questions.findIndex((q) => q.name === initialQuestion.name)

      setAmplitudeUserProperties(action.uid, answers)

      return {
        initialized: true,
        uid: action.uid,
        config: action.config,
        theme: action.theme,
        funnel: action.funnel,
        stepper: computeStepperProgress(action.config, 0),
        milestoneStepper: computeMilestonStepperProgress(action.config, questionIndex + 1),
        currentQuestion: initialQuestion,
        prevQuestion: getSiblingQuestion(action.config.questions, initialQuestion.name, true),
        nextQuestion: getSiblingQuestion(action.config.questions, initialQuestion.name),
        canStepNext: validateQuestion(initialQuestion, answers),
        answers,
      }
    }

    case "QUESTION_ANSWER_CHANGED": {
      if (state.initialized) {
        const answer =
          action.name === state.currentQuestion?.name
            ? computeAnswer(state.currentQuestion, state.answers[action.name], action.value)
            : action.value

        setAmplitudeUserProperty(action.name, answer)
        return createNextStateWithQuestion(
          {
            ...state,
            answers: {
              ...state.answers,
              [action.name]: answer,
            },
          },
          state.currentQuestion
        )
      } else {
        return state
      }
    }
    case "QUESTION_COMPLETED": {
      if (state.initialized) {
        trackAmplitudeAndGTAGEvent("survey_step_completed", {
          step: state.currentQuestion.path,
        })

        let nextQuestion = getSiblingQuestion(state.config.questions, state.currentQuestion.name)

        // Keep moving forward until we find a question that should be rendered
        while (nextQuestion && nextQuestion.shouldRender && !nextQuestion.shouldRender(state.answers)) {
          nextQuestion = getSiblingQuestion(state.config.questions, nextQuestion.name)
        }

        if (nextQuestion) {
          return createNextStateWithQuestion(state, nextQuestion)
        }
      }
      return state
    }
    case "QUESTION_STEP_BACK": {
      if (state.initialized) {
        let prevQuestion = getSiblingQuestion(state.config.questions, state.currentQuestion.name, true)

        // Keep going back until we find a question that should be rendered
        while (prevQuestion && prevQuestion.shouldRender && !prevQuestion.shouldRender(state.answers)) {
          prevQuestion = getSiblingQuestion(state.config.questions, prevQuestion.name, true)
        }

        if (prevQuestion) {
          const eventData = {
            from: state.currentQuestion.path,
            to: prevQuestion.path,
          }
          trackAmplitudeAndGTAGEvent("survey_step_back", eventData)

          return createNextStateWithQuestion(state, prevQuestion)
        }
      }
      return state
    }
    case "SET_QUESTION": {
      if (state.initialized) {
        const index = state.config.questions.findIndex((q) => q.path === action.path)
        if (index >= 0) {
          return createNextStateWithQuestion(state, state.config.questions[index])
        }
      } else {
        return state
      }
      break
    }
    default:
      throw new Error()
  }
}

export const loadSurvey = async (uid: string): Promise<Answers> => {
  try {
    const ref = await getDoc(doc(firestore, "user_web_survey", uid))
    if (ref.exists) return ref.exists ? ref.data() : {}
  } catch (e) {
    Sentry.captureException(e)
    return {}
  }
}

const initialState: SurveyState = { initialized: false }

const surveyContext = createContext<[SurveyState, Dispatch<Action>]>(null)

export const SurveyProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.initialized && state.uid) {
      setDoc(doc(firestore, "user_web_survey", state.uid), _(state.answers).omitBy(_.isNil).value())
    }
  }, [state])

  return <surveyContext.Provider value={[state, dispatch]}>{children}</surveyContext.Provider>
}

export const useSurvey = (): [SurveyState, Dispatch<Action>] => {
  return useContext(surveyContext)
}

const questionContext = createContext<[Question, Answers, Dispatch<Action>]>(null)

export interface QuestionProviderProps {
  question: Question
  answers: Answers
  dispatch: Dispatch<Action>
}

export const QuestionProvider: React.FC<React.PropsWithChildren<QuestionProviderProps>> = ({
  question,
  answers,
  dispatch,
  children,
}) => {
  return <questionContext.Provider value={[question, answers, dispatch]}>{children}</questionContext.Provider>
}

export const useQuestion = (): [Question, Answers, Dispatch<Action>] => {
  return useContext(questionContext)
}
